import './App.css';

function App() {
  return (
    <div>
      Are We On Mars Yet? <strong>NO</strong>
    </div>
  );
}

export default App;
